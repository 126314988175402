import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { VOLUNTEER_FORM } from "src/constants";
import { Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import Blockquote from "src/components/Blockquote";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import Layout from "src/components/Layout";
import Paragraph from "src/components/Paragraph";
import SubHeader from "src/components/SubHeader";
import joanneImg from "src/images/work-with-us/Joanne Riley.jpg";
import laQuoiaImg from "src/images/work-with-us/LaQuoia Palmore.jpg";
import bg from "src/images/work-with-us/work-with-us-bg.jpg";
import bottomImg from "src/images/work-with-us/work-with-us.jpg";

const JOBS = [
  {
    title: "Case Manager - North County (Position - location in Oakland)",
    url: "https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=78851&clientkey=1EB32FC627C2A157DE15C7A11F456F77"
  },
  {
    title: "Family Case Manager - MC FIT",
    url: "https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=80902&clientkey=1EB32FC627C2A157DE15C7A11F456F77"
  },
  {
    title: "SSVF Shallow Subsidy Housing Navigator",
    url: "https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=86158&clientkey=1EB32FC627C2A157DE15C7A11F456F77"
  },
  {
    title: "Case Manager SSVF in RRH",
    url: "https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=86148&clientkey=1EB32FC627C2A157DE15C7A11F456F77"
  },
  {
    title: "Respite Care Program Assistant",
    url: "https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=86235&clientkey=1EB32FC627C2A157DE15C7A11F456F77"
  },
  {
    title: "Payroll Accountant",
    url: "https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=87939&clientkey=1EB32FC627C2A157DE15C7A11F456F77"
  },
  {
    title: "Executive Director in General Administration",
    url: "https://www.paycomonline.net/v4/ats/web.php/jobs/ViewJobDetails?job=90419&clientkey=1EB32FC627C2A157DE15C7A11F456F77"
  },
]

const QUOTES = [
  {
    name: "Joanne Riley",
    position: "Cook at Crossroads Shelter",
    quote: (
      <>
        I’ve worked at EOCP for 13 years, and <b>I get to do what I love</b> —
        cook food! I remember a resident who first arrived at Crossroads
        struggling with being underweight, but after eating three meals per day,
        he was able to reach a healthy weight. People have given me a call and
        said, “Thank you for feeding me so well.” Having that kind of an impact
        makes me feel good — I know it is appreciated by our residents.{" "}
        <b>
          I have learned so much about how to support people who are going
          through some of the hardest times in their lives, and how to have
          patience and understanding.
        </b>
      </>
    ),
    img: joanneImg,
  },
  {
    name: "LaQuoia Palmore",
    position: "Family Case Manager/Housing Specialist",
    quote: (
      <>
        I love interacting with families and kids, and creating a bond with them
        is a wonderful feeling. I enjoy being able to help people with
        employment, accessing donations, and working on life skills to get them
        back on their feet. I am making an impact by helping families not only
        with permanent housing, but also with stable jobs and quality education.{" "}
        <b>
          Working at EOCP has shown me how important it is to be dedicating my
          career to helping people experiencing homelessness, to make a real
          difference for families in my community. If you love to work with
          people, and like to give back to your community, EOCP is the place to
          be!
        </b>
      </>
    ),
    img: laQuoiaImg,
  },
];

export default function WorkWithUsPage() {
  return (
    <Layout>
      <Helmet>
        <title>Work With Us</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle="Work With Us"
        title="Join Our Team"
        img={bg}
        backgroundPosition="50% 15%"
        maxTitleWidth={"15em"}
      />
      <Container>
        <Grid container spacing={{ sm: 6, md: 4 }}>
          <Grid item xs={12} sm={6}>
            <Box mb={4}>
              <SubHeader>Open Roles</SubHeader>
              <Paragraph>
                We welcome candidates from a wide range of backgrounds to
                support our team’s committed work. Check out our openings to see
                if there’s a fit for you at EOCP!
              </Paragraph>

              <Box mb={2}>
                {JOBS.length === 0 && (
                  <Typography variant="subtitle1">
                    <i>No open roles at this time.</i>
                  </Typography>
                )}
                {JOBS.map(({ title, url }) => {
                  return (
                    <Button
                      key={url}
                      fullWidth
                      component={Link}
                      to={url}
                      variant="contained"
                      sx={{ mt: 1, justifyContent: "start" }}
                    >
                      {title}
                    </Button>
                  );
                })}
              </Box>

            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SubHeader>Our philosophy and values</SubHeader>
            <Blockquote my={0} mb={3}>
              East Oakland Community Project is led by dedicated and
              compassionate staff who serve people and families experiencing
              homelessness with a high level of responsibility and integrity.
              <br />
              <br />
              Across the organization, we value diversity in all its forms,
              embrace initiative and a strong ethic of caring for others, and
              support personal growth and positive change. We work
              collaboratively and inclusively across residents, staff, board,
              and the greater community!
            </Blockquote>
          </Grid>
        </Grid>
        <SubHeader>In the words of our staff...</SubHeader>
        <Grid container spacing={{ sm: 6, md: 4 }}>
          {QUOTES.map(({ quote, name, position, img }) => (
            <Grid item xs={12} sm={6} md={4} key={name} mb={3} container>
              <Paragraph>
                <Box
                  component="img"
                  src={img}
                  alt={name}
                  sx={{
                    width: { xs: "33vw", sm: "20vw", md: "100%" },
                    float: { xs: "left", md: "none" },
                    mr: { xs: 3, md: 0 },
                  }}
                  mb={2}
                />
                {quote}
                <Typography
                  variant="body2"
                  component="span"
                  display="block"
                  mt={2}
                >
                  <b>— {name},</b> {position}
                </Typography>
              </Paragraph>
            </Grid>
          ))}
        </Grid>
        <Box display={{ sm: "flex" }}>
          <Box>
            <Box
              component="img"
              src={bottomImg}
              sx={{
                display: "block",
                mx: "auto",
                mr: { sm: 3 },
                mb: 3,
                width: { xs: "100%", sm: "33vw" },
                maxWidth: 320,
              }}
            />
          </Box>
          <Box>
            <Box my={4}>
              <SubHeader>Volunteer</SubHeader>
              <Paragraph>
                Due to the COVID-19 pandemic and its impacts on our staff and
                protocols, our ability to onboard new volunteers is limited.
                However, we’d love to learn about your interest in volunteering!
                Please complete our interest form, and we will contact you as
                soon as opportunities are available.
              </Paragraph>
              <Button variant="contained" href={VOLUNTEER_FORM} component="a">
                Volunteer Interest Form
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
